#enter-container{
    position: absolute;
    background: white;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}
#stamper-red{
    opacity: 0;
    position: absolute;
    bottom: 60px;
    width: 63px;
    z-index: 2;
}
#stamper-grey{
    position: absolute;
    bottom: 60px;
    width: 63px;
}

#smart-contract{
    position: absolute;
    left:0;
    transform: rotate(90deg);
    font-size: 12px;
    line-height: 13px;
    color: #000000;
    z-index: 3;
}

#point-container{
    position: absolute;
    bottom: 27px;
    width: 40px;
    z-index: 4;
    animation: blink 1.5s linear infinite;
}

#point-container img{
    width: 100%;
}

@keyframes blink{
    0%{opacity: .2;}
    25%{opacity: .5}
    50%{opacity: .7;}
    75%{opacity: .5}
    100%{opacity: .2;}
}

@media (orientation: landscape) {
    #loading-hor{
        display: flex;
    }

    #loading-vertical{
        display: none;
    }

    #stamper-red{
        transform: rotate(-90deg);
        right: 40px;
    }
    #stamper-grey{
       transform: rotate(-90deg);
        right: 40px;
    }
    #point-container{
        right: 25px;
        transform: rotate(-90deg);
        bottom: 35px;
    }
    #loading-container{
        bottom: 45px;
    }
}

@media (orientation: portrait) {
    #loading-vertical{
        display: flex;
    }

    #loading-container{
        position: absolute;
        left:20px;
        transform: rotate(90deg);
    }

    #loading-hor{
        display: none;
    }

    #stamper-red{
        left: 25px;
    }
    #stamper-grey{
        left: 25px;
    }
    #point-container{
        left: 20px;
    }
}