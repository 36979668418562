.nfo-gallery {
  padding: 0.25rem 1rem;
  background: linear-gradient(263.91deg, #aa8964 12.63%, #e7d7bc 100.65%);
  box-shadow: 0px 1px 1px rgba(172, 139, 103, 0.4);
  border-radius: 0 30px 30px 0;
  color: #ffffff;
}

.activity-description {
  padding: 0.25rem 1rem;
  background: linear-gradient(74.13deg, #aa8964 0%, #e7d7bc 87.8%);
  box-shadow: 0px 1px 1px rgba(172, 139, 103, 0.4);
  border-radius: 226px 0 0 226px;
  color: #ffffff;
}

.detail-card-border {
  background-image: url("../../assets/detail-page-border.png");
  background-size: 100% 100%;
}

button.sync-nickname {
  width: 5rem;
  height: 1.8125rem;
  border: 1px solid;
  color: #AA8964;
  filter: drop-shadow(0px 1px 1px rgba(172, 139, 103, 0.4));
  border-radius: 4px;
}

button.nickname {
  width: 76px;
  height: 29px;
}

button.nickname.confirm {
  color: #ffffff;
  background: linear-gradient(95.71deg, #aa8964 0.47%, #e7d7bc 99.37%);
  box-shadow: 0px 1px 1px rgba(172, 139, 103, 0.4);
  border-radius: 4px;
}

button.nickname.cancel {
  color: #aa8964;
  border: 1px solid;
  border-radius: 4px;
  box-shadow: 0px 1px 1px 0px #ac8b6766;
  filter: drop-shadow(0px 1px 1px rgba(172, 139, 103, 0.4));
}

button:disabled.sync-nickname {
  background: linear-gradient(95.71deg, #7e7e7e 0.47%, #cfcfcf 99.37%);
  box-shadow: none;
  border: none;
  color: white;
  filter: drop-shadow(0px 1px 1px 77ee7e);
  border-radius: 4px;
}

.activity-content p {
  margin-top: 1rem;
  text-align: justify;
}