#vertical-loading{
    position: absolute;
    font-size: 14px;
    line-height: 28px;
    color: #2D455F;
    -webkit-text-stroke: 1px #FFFFFF;
    text-shadow: 0px 0px 1px #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#congrats-container{
    opacity: 0;
    transition: opacity 3s cubic-bezier(0.45, 0, 0.55, 1);
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index:2;
    left:0;
    top:0;
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 28px;
    color: #2D455F;
    -webkit-text-stroke: 0.75px #FFFFFF;
}


#retrieve-button{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    bottom: 150px;
    width: 76px;
    height:29px;
    background: linear-gradient(95.71deg, #AA8964 0.47%, #E7D7BC 99.37%);
    box-shadow: 0px 1px 1px rgba(172, 139, 103, 0.4);
    border-radius: 4px;
}

#vertical-enter{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.loading-content{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}