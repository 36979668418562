@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "FZZhengHeiS", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.glass-tty {
  font-family: "Glass TTY VT220", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

@font-face {
  font-family: "FZZhengHeiS";
  src: url("../src/assets/FZZHJ.OTF") format("truetype");
}

@font-face {
  font-family: "Glass TTY VT220";
  src: url("../src/assets/Glass_TTY_VT220.ttf") format("truetype");
}
