button.login-button {
  width: 76px;
  height: 29px;
  background: linear-gradient(95.71deg, #aa8964 0.47%, #e7d7bc 99.37%);
  box-shadow: 0px 1px 1px rgba(172, 139, 103, 0.4);
  border-radius: 4px;
  color: white;
}

button:disabled.login-button {
  background: linear-gradient(95.71deg, #7e7e7e 0.47%, #cfcfcf 99.37%);
  box-shadow: 0px 1px 1px rgba(48, 48, 48, 0.4);
}

#cancel {
  background: transparent;
  color: #aa8964;
  filter: drop-shadow(0px 1px 1px rgba(172, 139, 103, 0.4));
  border: 1px solid;
  border-radius: 4px;
}

.declaration p {
  margin-top: 1rem;
  text-align: justify;
  padding-right: 0.5rem;
}

.declaration-scroll {
  overflow: none;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border: 0.6px solid rgb(71, 71, 71);
  border-radius: 2px;
  padding-top: 7px;
  padding-left: 5px;
}

/* ::-webkit-scrollbar-track {
  width: 10px;
  background-color: rgba(57, 57, 57, 0.6);
  border-radius: 8px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  background-clip: padding-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  min-height: 10px;
}
.declaration-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
} */
