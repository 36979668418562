.oreo-card {
  /*width: 9.75rem;*/
  height: 9.75rem;
}

.gallery-title {
  width: 10rem;
  height: 2.4rem;
  background: url("../../assets/title-background.png");
}

.my-nfo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 29px;
  color: #ffffff;
  background: linear-gradient(95.71deg, #aa8964 0.47%, #e7d7bc 99.37%);
  box-shadow: 0px 1px 1px rgba(172, 139, 103, 0.4);
  border-radius: 4px;
}

.oreo-container {
  border: 1px solid #d5d8dc;
  border-left-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.oreo-container.item1,
.oreo-container.item3,
.oreo-container.item5 {
  border-right-width: 0;
}

.oreo-container.item4,
.oreo-container.item5 {
  border-bottom-width: 0;
}

.oreo-wrapper {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  border-bottom: 1px solid #d5d8dc;
}

.oreo-wrapper.item4,
.oreo-wrapper.item5 {
  border-bottom-width: 0;
}
